import '@gusto/gusto-analytics';

function jsonParseSafe(str, fallback = undefined) {
  try {
    return JSON.parse(str);
  } catch {
    return fallback;
  }
}

const instrumentClick = element => {
  element.addEventListener('click', event => {
    const { eventName, extraProps, view } = jsonParseSafe(element.dataset.instrumentClick, {});
    const { className, id, textContent } = event.target;
    const data = {
      target: {
        className,
        id,
        textContent: textContent.trim(),
      },
      view,
    };

    if (extraProps && extraProps.constructor === Object) {
      Object.assign(data, extraProps);
    }

    // Prevent recording the event for child element
    if (eventName === 'NavItemMobileClick') {
      event.stopPropagation();
    }

    GustoAnalytics.trackDomEvent(event);

    if (eventName !== 'DomClick') {
      GustoAnalytics.track({
        eventCategory: window.SNOWPLOW_EVENT_CATEGORIES.VISITOR_EVENT,
        eventName,
        data,
      });
    }
  });
};

const instrumentClickForCMS = element => {
  element.addEventListener('click', event => {
    GustoAnalytics.trackDomEvent(event);
  });
};

const nodes = document.querySelectorAll('[data-instrument-click]');
nodes.forEach(element => instrumentClick(element));

if (window.isCMS) {
  const anchorNodes = document.querySelectorAll('a');
  anchorNodes.forEach(element => {
    if (!element.hasAttribute('data-instrument-click')) {
      instrumentClickForCMS(element);
    }
  });
}

// Site-wide data layer
window.GustoAnalytics.dataLayer({ app_name: 'gcorp' });
